
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"22e9a15824da803bc44f3ad36abfe7d963b3f163":"getAllArticlesWithShowInNews","8628c4bfc877e7be3d4fc88d8042236fb352598f":"getAllBrands","9cc7676025c17c7f81f9f3211a17c6991e30ae96":"getAllMobiles","b225cc2e9ff0438dea6505628ee688330d0d36a9":"getAllArticles"} */ export var getAllBrands = createServerReference("8628c4bfc877e7be3d4fc88d8042236fb352598f");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getAllArticles = createServerReference("b225cc2e9ff0438dea6505628ee688330d0d36a9");
export var getAllArticlesWithShowInNews = createServerReference("22e9a15824da803bc44f3ad36abfe7d963b3f163");
export var getAllMobiles = createServerReference("9cc7676025c17c7f81f9f3211a17c6991e30ae96");

